@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #373737;
    @apply max-w-2xl mx-auto overflow-y-auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-blue-0 {
    background-color: #EAF2FF;
}

.text-blue-0 {
    color: #EAF2FF;
}

.bg-blue-1 {
    background-color: #007FFE;
}

.border-blue-1 {
    border-color: #007FFE;
}

.text-blue-1 {
    color: #007FFE;
}

.text-red-1{
    color: #eb4435
}

.bg-red-1 {
    background-color: #eb4435;
}

.bg-white{
    background-color: white;
}

input {
    @apply text-center w-full bg-gray-100 focus:border-blue-500 rounded p-2 mb-2;
}

.picture {
    position: relative;
    margin-top: 9px;
}

.close {
    font-size: 50;
    position: absolute;
    top: 0px;
}

.close:focus {
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

